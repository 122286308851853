(function () {
    "use strict";
    $(document).ready(function () {
        $(".key-adv.clip-none img").wrap("<span></span>");
        function enableIcon() {
            $(".top-search img, .top-search svg").show();
        }
        setTimeout(enableIcon, 800);
        // New menu starts
        $(".main-nav ul li.nav > a[href='#']").click(function (e) {
            e.preventDefault();
        });
        $(".main-nav > ul > li").on('mouseenter mouseleave', function (e) {
            if ($(this).find('.submenu-outer').length > 0) {
                $(this).find('a').attr('aria-expanded', e.type === 'mouseenter');
            }
        });
        if ($(window).width() <= 1275) {
            $('.main-nav ul li.nav:has(.submenu-outer)').addClass("accordion-content");

            $('.main-nav ul li.nav > a').click(function () {
                let checkElement = $(this).next();
                $('.main-nav li.nav').removeClass('active');
                $(this).closest('li.accordion-content').addClass('active');

                if (this.text === 'School') {
                    $("li#school").next().addClass("menu-box-shadow");
                } else {
                    $("li#school").next().removeClass("menu-box-shadow");
                }
                // $(this).closest('li.accordion-content').addClass('active');

                if (this.text === 'Home') {
                    $("li#home").next().addClass("menu-box-shadow");
                } else {
                    $("li#home").next().removeClass("menu-box-shadow");
                }

                if (this.text === 'Products') {
                    $("li#products").next().addClass("menu-box-shadow");
                } else {
                    $("li#products").next().removeClass("menu-box-shadow");
                }

                if (this.text === 'Solutions') {
                    $("li#solutions").next().addClass("menu-box-shadow");
                } else {
                    $("li#solutions").next().removeClass("menu-box-shadow");
                }

                if (this.text === 'Customer Success') {
                    $("li#customer-success").next().addClass("menu-box-shadow");
                } else {
                    $("li#customer-success").next().removeClass("menu-box-shadow");
                }

                if (this.text === 'Resources') {
                    $("li#resources").next().addClass("menu-box-shadow");
                } else {
                    $("li#resources").next().removeClass("menu-box-shadow");
                }

                if ((checkElement.is('.submenu-outer')) && (checkElement.is(':visible'))) {
                    $(this).closest('li.nav').removeClass('active');
                    $("li#school").next().removeClass("menu-box-shadow");
                    $("li#home").next().removeClass("menu-box-shadow");
                    $("li#products").next().removeClass("menu-box-shadow");
                    $("li#solutions").next().removeClass("menu-box-shadow");
                    $("li#customer-success").next().removeClass("menu-box-shadow");
                    $("li#resources").next().removeClass("menu-box-shadow");
                    checkElement.slideUp('normal');
                    $("html, body").removeClass("locked");
                }

                if ((checkElement.is('.submenu-outer')) && (!checkElement.is(':visible'))) {
                    $('.main-nav .submenu-outer:visible').slideUp('normal');
                    checkElement.slideDown('normal');
                    $("html, body").addClass("locked");
                }

                if (checkElement.is('.submenu-outer')) {
                    return false;
                } else {
                    return true;
                }
            });

            $('a.ham-icon').click(function () {
                $('.main-nav li.nav').removeClass('active');
                $('.main-nav .submenu-outer:visible').slideUp('normal');
                $("html, body").removeClass("locked");
                $(".head-top").toggleClass("active")

            });
        }
        // New menu ends

        //New footer starts

        $(".isDisabled").click(function (e) {
            e.preventDefault();
        });

        let d = new Date();
        let n = d.getFullYear();
        document.getElementById("copyright-year").innerHTML = n;

        if ($(window).width() <= 992) {
            $(".new-footer strong > a[tabindex='-1']").removeAttr('tabindex');
            $(".new-footer .footer-nav strong:not('#contactus, #stayconnected')").click(function (e) {
                let clickedElement = $(this).next();
                let thisEle = this;
                if ($(this).next().hasClass('mob-view')) {
                    $(this).find('img').removeClass('arrow-mob-icon');
                    $(this).next().slideUp(function () {
                        $(thisEle).next().removeClass('mob-view');
                        $(thisEle).removeClass('rmv-bdr');
                        $(thisEle).next('.isDisabled').attr('aria-selected', 'false');
                    });
                } else {
                    let openMenu = $(".new-footer .mob-view");
                    let downarrow = $(".footer-nav .arrow-mob-icon");
                    $(downarrow).removeClass('arrow-mob-icon');
                    $(openMenu).slideUp(function () {
                        $(openMenu).removeClass('mob-view');
                        $(openMenu).siblings('strong').removeClass('rmv-bdr');

                    });

                    $(this).next().addClass('mob-view');
                    $(this).find('img').addClass('arrow-mob-icon');
                    $(this).next().slideDown(function () {
                        $(thisEle).addClass('rmv-bdr');
                        $(thisEle).next('.isDisabled').attr('aria-selected', 'true');
                    });

                }

                e.preventDefault();
            });
        }

        //New footer ends

        $(".loginMenu").prev('a').removeAttr("href").addClass('noLink');
        $(".sign-up-btn").parent('li').prev("li").addClass('border-btn-outer');
        $(".sign-up-btn").parent('li').prev("li").prev("li").addClass('border-btn-outer');

        $(".more-info .col-xs-6:nth-child(2)").after("<div class='col-xs-12'></div>");


        //Smooth Scrolling
        $('a[href*="#"]')
            // Remove links that don't actually link to anything
            .not('[href="#"]')
            .not('[href="#0"]')
            .not('.profile-wrap a')
            .click(function (event) {
                // On-page links
                if (
                    location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
                    location.hostname == this.hostname
                ) {
                    // Figure out element to scroll to
                    let target = $(this.hash);
                    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                    // Does a scroll target exist?
                    if (target.length) {
                        // Only prevent default if animation is actually gonna happen
                        event.preventDefault();
                        $('html, body').animate({
                            scrollTop: target.offset().top - 100
                        }, 0, function () {
                            // Callback after animation
                            // Must change focus!
                            let $target = $(target);
                            $target.focus();
                            if ($target.is(":focus")) { // Checking if the target was focused
                                return false;
                            } else {
                                $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                                $target.focus(); // Set focus again
                            }
                        });
                    }
                }
            });

        function addButtons() {
            $('.main-nav > ul > li').each(function (idx, li) {
                if (($(li).find('ul')).length) {
                    $($(li).find('> a')).attr("aria-haspopup", "true");
                }
            });
        }
        addButtons();

        $('.slick-dots').on('keydown', function (e) {
            $(this).attr('role', 'listbox');
            $('.slick-dots li').each(function (idx, item) {
                $(item).attr('aria-hidden', 'false');
                $(item).attr('role', 'option');
            })
        });

        $('.slick-dots li').on('keydown', function () {
            if ($(this).hasClass('slick-active')) {
                $(this).attr('aria-selected', 'true');
            } else {
                $(this).attr('aria-selected', 'false');
            }
        });

        $('a').on('mousedown', function (e) {
            e.preventDefault();
        });

        $('body').mousedown(function () {
            $(this).addClass('using-mouse');
        });
        $('body').keydown(function () {
            $(this).removeClass('using-mouse');
        });

        let keys = {
            tab: 9,
            enter: 13,
        };

        /* Handle "enter" key press on submenu button for menu accessibility using keyboard */
        $('.submenu-btn').keypress(function (event) {
            if (event.which == keys.enter) {
                event.preventDefault();
                if ($(this).attr('aria-expanded') == 'false' || $(this).attr('aria-expanded') == null) {
                    $(this).parents("li:first").addClass('showsubmenu');
                    $(this).attr('aria-expanded', 'true');
                } else {
                    $(this).parents("li:first").removeClass('showsubmenu');
                    $(this).attr('aria-expanded', 'false');
                }
            }
        });


        /* Close submenus when navigating to different menu item */
        $('li.nav > a').focus(function () {
            $(document).click();
        });

        /* Close submenus when clicked outside navigation */
        $(document).click(function () {
            let open = $('li.showsubmenu');
            open.each(function (elem) {
                $(this).removeClass('showsubmenu');
                $(this).find('.submenu-btn').attr("aria-expanded", "false");
            });
        });


        $("body").css({
            visibility: 'visible'
        });
        let wpOffset = 80,
            $portfolioContainer = $('.portfolio-container'),
            $articleContainer = $('.article-container'),
            isMobile = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));

        /*Login functions for Parents*/
        $("#parent-login").submit(function (e) {
            $('.pass-error').remove();
            let re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
            if (re.test($('#login-password').val()) && e.target.checkValidity()) {
                $.ajax({
                    type: "POST",
                    url: gUrlHome + "/_login.php",
                    data: {
                        email: $('#cemail').val(),
                        password: $('#login-password').val()
                    },
                    dataType: JSON,
                    complete: function (xhr, textStatus) {
                        if (xhr.status == 200) {
                            window.location.href = "/app/#/";
                        } else if (xhr.status == 500) {
                            $("#normal-login h3").append('<span class="pass-error">Inncorrect email or password</span>');
                        } else if (xhr.status == 401) {
                            $("#normal-login h3").append('<span class="pass-error">Inncorrect email or password</span>');
                        }
                    },
                    error: function (jqXHR, textStatus, errorThrown) {

                    }
                });
            } else if (!re.test($('#login-password').val())) {
                $('pass-error').remove();
                $('#normal-login h3').append('<span class="pass-error" style="color:red">Password or Username is invalid.</span>');
            }
            return false;
        });

        $("#reset-login").submit(function (e) {
            $('.pass-error').remove();
            if (e.target.checkValidity()) {
                $.ajax({
                    type: "GET",
                    url: gUrlHome + "/_passwordReset?email=" + encodeURIComponent($('#reset-email').val()),
                    complete: function (xhr, textStatus) {
                        if (xhr.status == 200) {
                            $('#reset-login').append('<span class="pass-error">An email has been sent to ' + $('#reset-email').val() + '</span><br><span>Please click the link in that email to reset your password in the next 72 hours.</span><br><button onclick="window.location.reload()">Refresh and Login</button>');
                            $('#reset-password-button').remove();
                            $('.reset-input').remove();
                        } else if (xhr.status == 500) {
                            $('#reset h3').append('<span class="pass-error" style="margin-bottom:0px">This email is not registered.</span>');
                        }
                    },
                    error: function (jqXHR, textStatus, errorThrown) {

                    }
                });
            }
            return false;
        });

        $("#reset-password").submit(function (e) {
            if ($('#confirm').val() != '') {
                return false;
            }
            $('.pass-error').remove();
            if ($('#password-reset').val() != $('#password-reset-conf').val()) {
                $('#reset-password').append('<span class="error" style="color:red">Password mismatch</span>');
                return false;
            };
            let re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
            if (re.test($('#password-reset').val()) && e.target.checkValidity()) {
                let token = getUrlParameter('token');
                if (!token) {
                    window.location.href = "/app/#/";
                }
                $.ajax({
                    type: "POST",
                    url: gUrlHome + "/_passwordReset",
                    data: {
                        password: $('#password-reset').val(),
                        token: token
                    },
                    complete: function (xhr, textStatus) {
                        if (xhr.status == 200) {
                            /*go to login page*/
                            window.location.href = "/app/#/";
                            /*document.getElementById('login-btn').click();*/
                        } else if (xhr.status == 500) {
                            $('.forgot-password').append('<span class="pass-error">Inncorrect email or password</span>');
                        }
                    },
                    success: function (xhr, textStatus) {
                        //console.log(xhr, textStatus, 'status scuccs');
                    },
                    error: function (jqXHR, textStatus, errorThrown) {

                    }
                });
            } else if (!re.test($('#parent-password').val())) {
                $('#reset-password').append('<span class="error" style="color:red">Password must contain at least one capital and one lowercase letter along with a number.</span>');

            }
            return false;
        });

        // Fade in body on page load (JS enabled only!)
        $('body').addClass('loaded');

        //News and Events Tab 

        $('ul.tabs').each(function () {
            let $active, $content, $links = $(this).find('a');
            $active = $($links.filter('[href="' + location.hash + '"]')[0] || $links[0]);
            $active.addClass('active');
            $content = $($active[0].hash);
            $links.not($active).each(function () {
                $(this.hash).hide();
            });
            $(this).on('click', 'a', function (e) {
                $active.removeClass('active');
                $content.hide();
                $active = $(this);
                $content = $(this.hash);
                $active.addClass('active');
                $content.show();
                // e.preventDefault();
            });
        });

        // Resources page mobile tab 

        $('.mb-tab select').change(function () {
            $(this).find("option:selected").each(function () {
                let optionValue = $(this).attr("value");
                if (optionValue) {
                    $(".tab-page").not("#" + optionValue).hide();
                    $("#" + optionValue).show();
                } else {
                    $(".tab-page").hide();
                }
            });
        });

        //News and Events tab styling
        $('.news-section .tabs').each(function () {
            let $active, $links = $(this).find('a');
            $links.removeClass('active');
            $active = $links.filter('[href="/' + location.pathname.split("/")[1] + '"]');
            $active.addClass('active');
            $(this).on('click', 'a', function (e) {
                $active.removeClass('active');
            });
        });

        $('input').blur(function () {
            if ($(this).val().length != 0) {
                $(this).addClass('white-input');
            }
        });

        //Show Hints
        let timer;
        $(".ipadd").on("mouseenter", function () {
            timer = setTimeout(function () {
                $(".ipadd + .bubble").removeClass("hidden");
            }, 500);
        }).on("mouseleave", function () {
            clearTimeout(timer);
            $(".bubble").addClass("hidden");
        });

        $(".sources").on("mouseenter", function () {
            timer = setTimeout(function () {
                $(".sources .bubble").removeClass("hidden");
            }, 500);
        }).on("mouseleave", function () {
            clearTimeout(timer);
            $(".bubble").addClass("hidden");
        });

        //  POPUPs
        $('#forgot-password').on('click', function () {
            $('#normal-login').addClass('hidden');
            $('#reset').removeClass('hidden');
        });
        $('#retun-login').on('click', function () {
            $('#normal-login').removeClass('hidden');
            $('#reset').addClass('hidden');
        });
        $('.popup-vimeo').magnificPopup({
            type: 'iframe'
        });
        $('#login-btn').magnificPopup({
            items: {
                src: '#login',
                type: 'inline'
            }
        });
        $('#parent-video-btn').magnificPopup({
            items: {
                src: '#parent-video',
                type: 'inline'
            }
        });
        $('.already').magnificPopup({
            items: {
                src: '#login',
                type: 'inline'
            }
        });

        $('.get-started-link').magnificPopup({
            items: {
                src: '#sign-up',
                type: 'inline'
            }
        });
        $('.get-started').magnificPopup({
            items: {
                src: '#sign-up',
                type: 'inline'
            }
        });
        $('.login-btn').on('click', function () {
            $('.main-nav').removeClass('trigger-active');
        });

        //NEWSLETTER POPUP
        $('.newsletter-btn').magnificPopup({
            items: {
                src: '#freetrial_inline',
                type: 'inline'
            }
        });

        //WHITE PAPER POPUP
        $('.whitepaper-btn').magnificPopup({
            items: {
                src: '#whitepaper_inline',
                type: 'inline'
            }
        });

        //LEARN MORE POPUP
        $('.learn-more-btn').magnificPopup({
            items: {
                src: '#learn-more-form',
                type: 'inline'
            }
        });

        // Toggle side-active class, show/hide meta menu
        $(document).on("click", ".side-active .page-content, .side-active .page-top, body:not(.side-active) .side-meta, body:not(.side-active) .toggle-side, .close-side", function () {
            $('body').toggleClass('side-active');
        });

        // Mobile: open menu on nav trigger tap
        $(document).on("click", ".nav-trigger", function (e) {
            e.preventDefault();
            $(this).toggleClass('active');
            $(this).attr('aria-expanded', function (i, attr) {
                return attr == 'true' ? 'false' : 'true';
            });

            $('.main-nav').toggleClass('trigger-active');

            $('.main-nav > ul').append('<div class="endofmenu" tabindex="0"></div>'); //flag keyboard focus reached last menu item

        });

        $(document).on('focus', '.endofmenu', function () {
            $('.nav-trigger').focus();
            $('.nav-trigger').click();
        });


        $('.nav-trigger').keypress(function (event) {
            $(this).click();
        });


        $(document).on("click", "#login-btn", function () {
            $(this).toggleClass('active');
            $('.main-nav').toggleClass('trigger-active');
        });

        // Vertical tabs move to tab content top on nav click
        $(document).on("click", '.vertical-tabs .tab-nav a', function () {
            $('html, body').stop(true, true).animate({
                scrollTop: $(this).closest('.vertical-tabs').find('.tab-content').offset().top
            }, 200);
        });

        // Main header enter button click, move wrap to top
        $(document).on("click", ".to-top", function () {
            $('html, body').animate({
                scrollTop: 0
            }, 1000);

            return false;
        });

        // Init parallax effect on page header backgrounds
        function initParallax() {
            if (!isMobile) {
                $('.page-head, .parallax').each(function () {
                    $(this).css('background-attachment', 'fixed').parallax("50%", 0.5, true);
                });
            }
        }
        initParallax();

        // Create header top bar blur effect
        function createHeaderBlur() {
            let headImage = $('.page-head').css('background-image');

            if (headImage !== undefined) {
                $('.cssfilters .head-top').append('<div class="background-blur">').find('.background-blur').css('background-image', headImage);
            }
        }
        createHeaderBlur();

        // Windows resize function
        $(window).resize(function () {
            initParallax();
            // $portfolioContainer.isotope();
            // $articleContainer.isotope();
        });

        // Init any Magnific Popup plugin
        // For all available options, check here: http://dimsemenov.com/plugins/magnific-popup/documentation.html#options
        $('.gallery-container').magnificPopup({
            delegate: 'a',
            type: 'image',
            gallery: {
                enabled: true
            },
            removalDelay: 300,
            mainClass: 'mfp-fade'
        });

        $('.popup-iframe').magnificPopup({
            type: 'iframe',
            mainClass: 'mfp-fade',
            removalDelay: 300,
            preloader: false,

            fixedContentPos: false
        });

        $(document).on("click", ".portfolio-filter a", function () {
            let $this = $(this),
                selector;

            $this.closest('.portfolio-filter').find('a').removeClass('selected');
            $this.toggleClass('selected');

            selector = $this.data('filter');
            // $portfolioContainer.isotope({
            //     filter: selector,
            //     resizable: true
            // });

            return false;
        });

        // Init Google Maps
        function initGoogleMaps() {
            // Init on contact page
            if ($('#contact-map').length > 0) {
                let myLatlng = new google.maps.LatLng(37.40404, -121.97898),
                    mapOptions = {
                        center: myLatlng,
                        zoom: 19,
                        mapTypeId: google.maps.MapTypeId.SATELLITE,
                        scrollwheel: false
                        // disableDefaultUI: true
                    },
                    map = new google.maps.Map(document.getElementById("contact-map"), mapOptions),
                    marker = new google.maps.Marker({
                        position: myLatlng,
                        map: map,
                        title: "Come visit us"
                    });
            }
        }
        initGoogleMaps();
    });
}());

// Timeline accordion 
$(document).ready(function () {
    let timing = 7000;
    const accordions = [1, 2, 3, 4];
    const autoTransitions = [];
    const items = [];
    const itemCounts = [];

    accordions.forEach((num) => {
        items[num] = 0;
        itemCounts[num] = $(`#accordion${num} .panel`).length;

        const displayImage = () => {
            let ThisID = $(`#accordion${num} .active .panel-collapse`).attr("id");
            $(".group-section").addClass(ThisID);
            $(`.group-section-images a.${ThisID}`).show().siblings().hide();
            let getHref = $(`.group-section${num} a:visible`).attr("href");
            $(`.group-section${num} a.enlarge-image`).attr("href", getHref);
        };

        const transitionSlide = () => {
            items[num] = (items[num] + 1) % itemCounts[num];
            $(`#accordion${num} .panel`).removeClass("active");
            $(`#accordion${num} .active .panel-collapse`).collapse("hide");
            $(`#accordion${num} .panel-collapse`).removeClass("in");
            $(`#accordion${num} .panel`).eq(items[num]).addClass("active");
            $(`#accordion${num} .active .panel-collapse`).collapse("show");

            displayImage();

            $(`#accordion${num} .panel, .group-section${num} a`)
                .off("mouseenter mouseleave")
                .on("mouseenter", () => {
                    clearInterval(autoTransitions[num]);
                    $("body").addClass("paused");
                })
                .on("mouseleave", () => {
                    clearInterval(autoTransitions[num]);
                    autoTransitions[num] = setInterval(transitionSlide, timing);
                    $("body").removeClass("paused");
                });
        };

        autoTransitions[num] = setInterval(transitionSlide, timing);

        $(`#accordion${num} .panel`).click(function (e) {
            if ($(this).hasClass("active")) {
                e.stopPropagation();
                e.preventDefault();
                return false;
            }
            clearInterval(autoTransitions[num]);
            items[num] = $(this).index();
            $(`#accordion${num} .panel`).removeClass("active");
            $(`#accordion${num} .active .panel-collapse`).collapse("hide");
            $(`#accordion${num} .panel-collapse`).removeClass("in");
            $(`#accordion${num} .panel`).eq(items[num]).addClass("active");
            $(`#accordion${num} .active .panel-collapse`).collapse("show");

            displayImage();
            autoTransitions[num] = setInterval(transitionSlide, timing);
        });
    });

    // Image popup
    $(".image-link").magnificPopup({
        type: "image",
        fixedContentPos: false,
        mainClass: "mfp-with-zoom",
        callbacks: {
            open: function () {
                $("body").addClass("magnificpopupnoscroll");
                autoTransitions.forEach(clearInterval);
            },
            close: function () {
                $("body").removeClass("magnificpopupnoscroll");
                accordions.forEach(
                    (num) => (autoTransitions[num] = setInterval(() => transitionSlide(num), timing))
                );
            },
            resize: function () {
                let img = this.content.find("img");
                img.css("max-height", parseFloat(img.css("max-height")) * 0.85);
            },
        },
        image: {
            markup:
                '<div class="mfp-figure">' +
                '<div class="mfp-close"></div>' +
                '<div class="mfp-img"></div>' +
                "</div>",
        },
    });

    // Disable accordion on mobile view
    if ($(window).width() < 768) {
        $(".panel-group.custom-panel.timeline-panel .panel.panel-default").addClass("active");
        $(".panel-group.custom-panel.timeline-panel").removeAttr("id");
        $(".accordion-toggle").click(function () {
            $(this).removeAttr("href");
        });
    }

    // APS Case study page utm parameter
    const classroomUtmBtn = document.querySelector(".classroom-utm");
    const caseStudyUTMLink = window.location.href;
    if (caseStudyUTMLink == "https://www.securly.com/aps-securly?utm_campaign=AtlantaPublicSchools&utm_source=email&utm_medium=csexpansion&utm_content=APSCS" || caseStudyUTMLink == "https://www.securly.com/aps-securly?utm_campaign=AtlantaPublicSchools&utm_source=email&utm_medium=csexpansion&utm_content=APSCS#aps-case-study-cs" || caseStudyUTMLink == "https://www.securly.com/aps-securly?utm_term=undefined&utm_source=email&utm_medium=csexpansion&utm_campaign=AtlantaPublicSchools#aps-case-study-cs") {
        classroomUtmBtn.href = "#aps-case-study-cs";
        classroomUtmBtn.innerText = "Book a Demo";
        classroomUtmBtn.target = "_self";
    }

    // ARIA attributes
    $('.profile-popup').each(function () {
        const id = $(this).attr('id');
        const titleId = id + '-title';
        const descId = id + '-desc';

        $(this).attr('role', 'dialog');
        $(this).attr('aria-labelledby', titleId);
        $(this).attr('aria-describedby', descId);

        $(this).find('.large-heading').attr('id', titleId);
        $(this).find('.inner-popup div').attr('id', descId);
        $(this).find('a').attr('aria-label', 'LinkedIn');
    });

    $('.panel').each(function (index) {
        let id = $(this).attr('id');
        let titleId = 'heading' + (index + 1);
        let collapseId = 'collapse' + (index + 1);

        $(this).attr('role', 'region');
        $(this).attr('aria-labelledby', titleId);

        let title = $(this).find('.panel-title');
        title.attr('id', titleId);

        let link = title.find('a');
        link.attr('aria-expanded', 'true');
        link.attr('aria-controls', collapseId);

        let svg = link.find('svg');
        svg.attr('aria-hidden', 'true');

        let i = link.find('i');
        i.attr('aria-hidden', 'true');

        let collapse = $(this).find('.panel-collapse');
        collapse.attr('id', collapseId);
        collapse.attr('role', 'region');
        collapse.attr('aria-labelledby', titleId);
    });

    // Securly Features Ticker
    const tickerItems = $('.ticker-item');
    const fadeTime = 1000;
    const fadeWaitTime = 4000;
    let indexNum = 0;

    function getMaxHeight() {
        let maxHeight = 0;
        tickerItems.each(function () {
            const itemHeight = $(this).outerHeight();
            if (itemHeight > maxHeight) {
                maxHeight = itemHeight;
            }
        });
        return maxHeight;
    }

    function setContainerHeight() {
        const maxHeight = getMaxHeight();
        $('.ticker-text').height(maxHeight);
    }

    function showNextItem() {
        tickerItems.removeClass('active').hide();
        tickerItems.eq(indexNum).addClass('active').fadeIn(fadeTime, function () {
            setTimeout(function () {
                tickerItems.eq(indexNum).fadeOut(fadeTime, function () {
                    indexNum = (indexNum + 1) % tickerItems.length;
                    showNextItem();
                });
            }, fadeWaitTime);
        });
    }

    setContainerHeight();
    showNextItem();
});

// Lazy load background images
document.addEventListener("DOMContentLoaded", function () {
    function getCSSBackgroundImages() {
        const elements = document.querySelectorAll("*");
        elements.forEach(el => {
            const bgStyle = window.getComputedStyle(el).getPropertyValue("background");
            const matches = [...bgStyle.matchAll(/url\(["']?(.*?)["']?\)/g)];
            if (matches.length > 0) {
                const imageUrl = matches.pop()[1];
                if (imageUrl) {
                    el.dataset.bg = imageUrl;
                    el.style.backgroundImage = "none";
                    el.classList.add("lazy-bg");
                }
            }
        });
    }

    function lazyLoadBackgrounds() {
        const lazyBackgrounds = document.querySelectorAll(".lazy-bg");

        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    let el = entry.target;
                    let bgImage = el.dataset.bg;
                    if (bgImage) {
                        el.style.backgroundImage = `url('${bgImage}')`;
                        el.classList.remove("lazy-bg");
                        observer.unobserve(el);
                    }
                }
            });
        });

        lazyBackgrounds.forEach(bg => observer.observe(bg));
    }

    getCSSBackgroundImages();
    lazyLoadBackgrounds();
});